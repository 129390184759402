import React, { useState, useEffect } from 'react';
import { Link,useNavigate } from 'react-router-dom';

const CheckoutPage = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    address: '',
    city: '',
    postalCode: '',
  });
  const [cart, setCart] = useState([]);

  useEffect(() => {

    // Retrieve cart data from localStorage on component mount
    const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
    setCart(storedCart);
  }, []); // Empty dependency array ensures this effect runs only once on mount

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {

    if(localStorage.getItem('usertoken') != "" && localStorage.getItem('usertoken') != null){
          
     
   }else
   {
    navigate("/Login");
   }

    e.preventDefault();
    console.log('Form submitted:', formData);
    // Add logic to handle form submission (e.g., send data to backend, process payment)
  };

  const removeFromCart = (index) => {
    const updatedCart = [...cart];
    updatedCart.splice(index, 1);
    setCart(updatedCart);
    // Update localStorage to reflect the change
    localStorage.setItem('cart', JSON.stringify(updatedCart));


  };

  return (
    <div className="header">
      <div className='container-fluid '>
        <div className='row  innerheader' ></div>
        <div className="container">
          <h2 className="mb-4">Checkout</h2>
          <div className="row">
          {/*   <div className="col-md-6">
              <h4>Contact Information</h4>
              <form onSubmit={handleSubmit}>
             
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">Name</label>
                  <input type="text" className="form-control" id="name" name="name" value={formData.name} onChange={handleChange} required />
                  <label htmlFor="email" className="form-label">Email</label>
                  <input type="text" className="form-control" id="email" name="email" value={formData.email} onChange={handleChange} required />
                  <label htmlFor="mobile" className="form-label">mobile</label>
                  <input type="text" className="form-control" id="mobile" name="mobile" value={formData.mobile} onChange={handleChange} required />
                  <label htmlFor="remarks" className="form-label">Remarks</label>
                  <input type="text" className="form-control" id="remarks" name="remarks" value={formData.remarks} onChange={handleChange} required />
                
                </div>
            
                <button type="submit" className="btn btn-primary"><i className='fa fa-arrow-right'></i> Submit</button>  <Link to="/Home" className="btn btn-danger"><i className='fa fa-undo'></i> Back to Home</Link>
              </form>
            </div> */}
            <div className="col-md-12">
              <h4>Order Summary</h4>
              <ul className="list-group mb-3">
                {cart.length > 0 ? (
                  cart.map((item, index) => (
                    <li key={index} className="list-group-item">
                      <div className="d-flex justify-content-between">
                     <div style={{width:'50px'}}>{index+1}.</div>
                       <div style={{textAlign:'left',width:'150px'}}>{item.name}</div>
                        <div>{item.rate}</div>
                        <div> <button
                        className="btn btn-sm btn-outline-danger"
                        onClick={() => removeFromCart(index)}
                      >
                        Remove
                      </button></div> 
                      </div>
                     
                    </li>
                  ))
                ) : (
                  <li className="list-group-item">Your cart is empty</li>
                )}
              </ul>
              <h5>Total:  {cart.reduce((acc, item) => acc + item.rate, 0).toFixed(2)}</h5>

            </div>
            <div className="col-md-12">
            <form onSubmit={handleSubmit}>
             
             {/* <div className="mb-3">
               <label htmlFor="name" className="form-label">Name</label>
               <input type="text" className="form-control" id="name" name="name" value={formData.name} onChange={handleChange} required />
               <label htmlFor="email" className="form-label">Email</label>
               <input type="text" className="form-control" id="email" name="email" value={formData.email} onChange={handleChange} required />
               <label htmlFor="mobile" className="form-label">mobile</label>
               <input type="text" className="form-control" id="mobile" name="mobile" value={formData.mobile} onChange={handleChange} required />
               <label htmlFor="remarks" className="form-label">Remarks</label>
               <input type="text" className="form-control" id="remarks" name="remarks" value={formData.remarks} onChange={handleChange} required />
             
             </div> */}
         
             <button type="submit" className="btn btn-primary"><i className='fa fa-arrow-right'></i> BUY NOW</button>  <Link to="/Home" className="btn btn-danger"><i className='fa fa-undo'></i> Back to Home</Link>
           </form>
           </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutPage;
