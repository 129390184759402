import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Changepassword.css';

function Changepassword() {
    const navigate = useNavigate();
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setMessage("");
        setError("");

        // Basic validation
        if (newPassword !== confirmPassword) {
            setError("New password and confirmation do not match.");
            setIsSubmitting(false);
            return;
        }

        axios.post('https://api.myonventure.com/api/Customerregistration/changepassword', {
            currentPassword,
            newPassword
        }, {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('usertoken')}` }
        })
        .then((response) => {
            setIsSubmitting(false);
            setMessage("Password changed successfully.");
            navigate("/login"); // Redirect to login after successful password change
        })
        .catch((error) => {
            setIsSubmitting(false);
            if (error.response?.data?.message) {
                setError(error.response.data.message);
            } else {
                setError("An error occurred. Please try again.");
            }
        });
    };

    return (
        <div className="header">
        <div className='container '>
            <div className='row  innerheader' >
        <div className="change-password-container">
            <div className="change-password-form">
                <h2>Change Password</h2>
                <form onSubmit={handleSubmit}>
                    {message && <p className="alert alert-success">{message}</p>}
                    {error && <p className="alert alert-danger">{error}</p>}
                    <div className="form-group">
                        <label htmlFor="currentPassword">Current Password</label>
                        <input
                            type="password"
                            id="currentPassword"
                            name="currentPassword"
                            className="form-control"
                            value={currentPassword}
                            onChange={(e) => setCurrentPassword(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="newPassword">New Password</label>
                        <input
                            type="password"
                            id="newPassword"
                            name="newPassword"
                            className="form-control"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="confirmPassword">Confirm New Password</label>
                        <input
                            type="password"
                            id="confirmPassword"
                            name="confirmPassword"
                            className="form-control"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                        />
                    </div>
                    <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? 'Changing...' : 'Change Password'}
                    </button>
                </form>
            </div>
        </div>
        </div>
        </div>
        </div>
    );
}

export default Changepassword;
