import React, { useState, useEffect } from 'react';
import { Link,useNavigate } from 'react-router-dom';

const About = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    address: '',
    city: '',
    postalCode: '',
  });
  const [cart, setCart] = useState([]);

  useEffect(() => {

    // Retrieve cart data from localStorage on component mount
    const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
    setCart(storedCart);
  }, []); // Empty dependency array ensures this effect runs only once on mount

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {

    if(localStorage.getItem('usertoken') != "" && localStorage.getItem('usertoken') != null){
          
     
   }else
   {
    navigate("/Login");
   }

    e.preventDefault();
    console.log('Form submitted:', formData);
    // Add logic to handle form submission (e.g., send data to backend, process payment)
  };

  const removeFromCart = (index) => {
    const updatedCart = [...cart];
    updatedCart.splice(index, 1);
    setCart(updatedCart);
    // Update localStorage to reflect the change
    localStorage.setItem('cart', JSON.stringify(updatedCart));


  };

  return (
    <div className="header">
      <div className='container-fluid '>
        <div className='row  innerheader' ></div>
        <div className="container">
          <h2 className="mb-4">About Hamara Brand</h2>
          <div className="row">
         
<section class="py-3 py-md-5 py-xl-8">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-10 col-lg-8">
        <h3 class="fs-5 mb-2 text-secondary text-uppercase">About</h3>
        <p class="display-7 mb-4">Hamara Brand is a trailblazing platform in the media industry, recognized as the first in India to offer district-wise media coverage across the entire country. Unlike traditional advertising agencies, we operate as a cutting-edge aggregator for media buying and selling, bringing unprecedented organization and accessibility to the media procurement process.With over 14 years of experience as Brand Manager, during which we have successfully established over 410 brands, our expertise is deeply rooted in understanding the diverse and dynamic needs of the Indian market.Our team consists of industry experts, tech innovators, and customer-focused professionals who are dedicated to empowering brands by providing precise, localized media options. At Hamara Brand, we are not just a platform—we are your strategic partners, committed to revolutionizing the way media is bought and sold in India.</p>
        <button type="button" class="btn btn-lg btn-primary mb-3 mb-md-4 mb-xl-5">Discover More</button>
      </div>
    </div>
  </div>

  <div class="container overflow-hidden">
    <div class="row gy-4 gy-lg-0">
      <div class="col-12 col-lg-6">
        <article>
          <div class="card border-0">
            <img class="card-img-top img-fluid m-0" loading="lazy" src="./assets/img/about-img-1.jpg" alt="Our Vision"/>
            <div class="card-body border bg-white p-4">
              <div class="entry-header mb-3">
                <h2 class="card-title entry-title h4 mb-0">
                  <a class="link-dark text-decoration-none" href="#!">Our Vision</a>
                </h2>
              </div>
              <p class="card-text entry-summary text-secondary mb-3">Our vision is to create the largest and most comprehensive ecosystem in the media industry, bringing structure and organization to a traditionally fragmented space. At Hamara Brand, we aim to be the driving force behind an interconnected media marketplace, where brands, advertisers, and publishers can seamlessly collaborate and thrive. We aspire to redefine industry standards, fostering a future where media transactions are transparent, efficient, and universally accessible.</p>
            </div>
          </div>
        </article>
      </div>
      <div class="col-12 col-lg-6">
        <article>
          <div class="card border-0">
            <img class="card-img-top img-fluid m-0" loading="lazy" src="./assets/img/about-img-2.jpg" alt="Our Approach"/>
            <div class="card-body border bg-white p-4">
              <div class="entry-header mb-3">
                <h2 class="card-title entry-title h4 mb-0">
                  <a class="link-dark text-decoration-none" href="#!">Our Mission</a>
                </h2>
              </div>
              <p class="card-text entry-summary text-secondary mb-3">At Hamara Brand, our mission is to revolutionize the media buying and selling landscape by creating a transparent, efficient, and accessible platform for businesses of all sizes. We strive to empower brands with the tools and insights they need to optimize their media strategies, connect with their target audiences, and achieve their marketing goals. Through innovation, integrity, and a commitment to excellence, we aim to simplify the complexities of media transactions, ensuring value and success for our partners in every interaction.</p>
            </div>
          </div>
        </article>
      </div>
    </div>
  </div>
</section>


         



          
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;

