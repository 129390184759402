import React from 'react';
import { Link, useNavigate } from "react-router-dom";
import './Dashboard.css';

const Dashboard = () => {
    return (
        <div className="header">
        <div className='container-fluid '>
          <div className='row  innerheader' > 
             <div className="dashboard">
            <div className="sidebar">
                <h2>Dashboard</h2>
                <ul>
                    <li>{localStorage.getItem('customeruser')!="" && (
                <Link to="/Dashbord" className="me-1 border rounded py-1 px-3 nav-link d-flex align-items-center" >
                <i className="fas fa-home m-1 me-md-2 textpink"></i>
                <p className="d-none d-md-block mb-0 textgreen">Dashbord</p>
              </Link>
            )} </li>
                    <li>  <Link to="/Profileupdate" className="me-1 border rounded py-1 px-3 nav-link d-flex align-items-center" >
                <i className="fas fa-user m-1 me-md-2 textpink"></i>
                <p className="d-none d-md-block mb-0 textgreen">Profile</p>
              </Link></li>
                    <li>  <Link to="/Dashbord" className="me-1 border rounded py-1 px-3 nav-link d-flex align-items-center" >
                <i className="fas fa-gears m-1 me-md-2 textpink"></i>
                <p className="d-none d-md-block mb-0 textgreen">Settings</p>
              </Link></li>
                </ul>
            </div>
            <div className="main-content">
                <h1>Welcome to Your Dashboard</h1>
                <div className="summary">
                    <div className="card">
                        <h3>Summary</h3>
                        <p>Total Users: 1,200</p>
                        <p>Active Sessions: 300</p>
                        <p>Pending Orders: 45</p>
                    </div>
                </div>
                <div className="recent-activities">
                    <h3>Recent Activity</h3>
                    <ul>
                        <li>User John Doe signed in</li>
                        <li>Order #1234 was processed</li>
                        <li>New user Jane Smith registered</li>
                    </ul>
                </div>
            </div>
     </div>    </div>  </div>   </div>
    ); 
};

export default Dashboard;
