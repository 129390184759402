import React, { useState,useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Link, useLocation } from 'react-router-dom';

function Verifyemail() {

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const receivedValue = params.get('token');

    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");



    useEffect(() => {
      
        fetchItems(receivedValue);
      
      }, []);
    
    
      const fetchItems = async (receivedValueid) => {
        try {  console.log(receivedValue);
          const tokens='1';
          const response = await axios.get('https://api.myonventure.com/api/Customerregistration/Verifyemail?token='+receivedValueid+'');
          setMessage(response.data);
        } catch (error) {
          console.error('Error fetching items:', error);
        }
      };
  

    return (
        <div className="header">
        <div className='container '>
            <div className='row  innerheader' >
        <div className="forgot-password-container col col-12 col-md-6">
            <div className="forgot-password-form">
                <h2>Email Verification Information</h2>
                <h5>{message}</h5>
                <p className="mt-3">
                    <a href="/login">Back to Login</a>
                </p>
            </div>
        </div></div>
</div>    
</div>
    );
}

export default Verifyemail;
