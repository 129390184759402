import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './ForgotPassword.css';

function ForgotPassword() {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setMessage("");
        setError("");

        axios.post('https://api.myonventure.com/api/Customerregistration/forgotpassword', { email })
            .then((response) => {
                setIsSubmitting(false);
                setMessage("A password Information has been sent to your email.");
            })
            .catch((error) => {
                setIsSubmitting(false);
                if (error.response?.data?.message) {
                    setError(error.response.data.message);
                } else {
                    setError("An error occurred. Please try again.");
                }
            });
    };

    return (
        <div className="header">
        <div className='container '>
            <div className='row  innerheader' >
        <div className="forgot-password-container">
            <div className="forgot-password-form">
                <h2>Forgot Password</h2>
                <form onSubmit={handleSubmit}>
                    {message && <p className="alert alert-success">{message}</p>}
                    {error && <p className="alert alert-danger">{error}</p>}
                    <div className="form-group">
                        <label htmlFor="email">Enter your email address</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            className="form-control"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? 'Sending...' : 'Send Password'}
                    </button>
                </form>
                <p className="mt-3">
                    <a href="/login">Back to Login</a>
                </p>
            </div>
        </div></div>
</div>    
</div>
    );
}

export default ForgotPassword;
