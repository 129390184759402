
    import React, { useState, useEffect } from 'react';
    import axios from 'axios';

    import { Link, useLocation } from 'react-router-dom';
import '../Pages/Category.css';

    function Category() {

       
        const location = useLocation();
        const params = new URLSearchParams(location.search);
        const receivedValue = params.get('value');
        const receivedname = params.get('name');
      const [crudOperation, setCrudOperation] = useState('read'); // Default to 'read'
      const [items, setItems] = useState([]);
      const [catname, setcatname] = useState([]);
     
      const [formData, setFormData] = useState({
        id: '',
        name: '' ,
    
        
      });
      const [editingItemId, setEditingItemId] = useState(null);
    
      const handleSwitchOperation = (operation) => {
        setCrudOperation(operation);
      };
      useEffect(() => {
      
        fetchItems(receivedValue);
      
      }, []);
    
    
     
    
      const fetchItems = async (receivedValueid) => {
        try {  console.log(receivedValue);
          const tokens='1';
          const response = await axios.get('https://api.myonventure.com/api/SubCategory/subcategoryid?id='+receivedValueid+'&tokens='+tokens+'');
          setItems(response.data);
        } catch (error) {
          console.error('Error fetching items:', error);
        }
      };

      return(
<div className="header">
<div className='container '>
    <div className='row  innerheader' >
        <div className='col-12 col-md-4'><h4>{receivedname} Advertising</h4> </div>
        <div className='col col-md-5'></div>
        <div className='col-12 col-md-3 text-right'>
        <nav className='pull-right' >
        <ol class="breadcrumb " style={{float:'right'}}>
        <li class="breadcrumb-item"><a href="/">Home</a></li>
        <li class="breadcrumb-item breadcrumbtext active pull-right" aria-current="page" > {receivedname}</li>
        </ol>
        </nav>
        </div>
        <hr className='hr-12'/>
        </div>

    

        <div className='row'> 
           {items.map((item, index) => {
            const itemname=(item.name);
const topq=(item.topquestion1+': '+item.topans1).substring(0,32);
if(topq>32)
{
  topq=(item.topquestion1+': '+item.topans1).substring(0,34)+'...';
}
     return (

    <div className='col col-12 col-md-3 mb-3'>
        <Link to={{ pathname: '/Subcat', search: '?value='+item.id+'' }} className='cardlink' >
<div className="card cardstyle" >

<img  className='img-responsive imgcat' src={'https://api.myonventure.com/api/../images/'+item.image}/> 

 <div className="card-body">
    <h5 className="card-title tooltip-test"  title={item.name}>{itemname}</h5>
   
    <p className="card-text textgreen  tooltip-test" title={(item.topquestion1+': '+item.topans1)}><i class="fa-solid fa-circle-right textpink"></i> {topq} </p>
    <p className="card-text textgreen"><i class="fa-solid fa-circle-right textpink"></i> {item.topquestion2}: {item.topans2}</p> 
  
  {/*   <a href="#" className="btn btn-primary">Go somewhere</a> */}
  </div>
</div></Link>
   </div>
   ) }
     
   )}
       </div>
         
</div>
 </div>
      );
    }    
export default Category