import React, { useState, useEffect } from 'react';
import { Link,useNavigate } from 'react-router-dom';

const Privacypolicy = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    address: '',
    city: '',
    postalCode: '',
  });
  const [cart, setCart] = useState([]);

  useEffect(() => {

    // Retrieve cart data from localStorage on component mount
    const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
    setCart(storedCart);
  }, []); // Empty dependency array ensures this effect runs only once on mount

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {

    if(localStorage.getItem('usertoken') != "" && localStorage.getItem('usertoken') != null){
          
     
   }else
   {
    navigate("/Login");
   }

    e.preventDefault();
    console.log('Form submitted:', formData);
    // Add logic to handle form submission (e.g., send data to backend, process payment)
  };

  const removeFromCart = (index) => {
    const updatedCart = [...cart];
    updatedCart.splice(index, 1);
    setCart(updatedCart);
    // Update localStorage to reflect the change
    localStorage.setItem('cart', JSON.stringify(updatedCart));


  };

  return (
    <div className="header">
      <div className='container-fluid '>
        <div className='row  innerheader' ></div>
        <div className="container">
          <h2 className="mb-4">Privacy Policy</h2>
          <div className="row">
          Privacy Policy Effective Date: [03/10/2024]
We use Analytics to get aggregate data that helps us with various data points that help us take business decisions. Our vision is to make it easy for advertisers to discover the right media anywhere worldwide and advertising spaces available in India as well GCC countries. In that regard, we list various advertising spaces and media owner's media details on our site. Most of these are official tie-ups. Sometimes we also list some media owner's media details basis the publicly available information on their site with the objective of helping them generate more sales from the thousands of advertisers visiting our page. If you're a media owner whose media has been listed and you'd like us to discontinue showing it on our site, please write in to vendor@hamarabrand.com and we'll take your listing off the site within 3 working days. We have noble intentions and would love to work with partners who understand and appreciate the value we are trying to drive to advertisers and media owners alike.
<br/>

Introduction Welcome to [Myon Venture pvt. Ltd ]. We are committed to protecting your privacy and ensuring a secure experience when using our Privacy Policy Effective Date: [03/10/2024] When you browse our site, you agree to the below terms.
<br/>
<ul>
    <li>
1. Introduction Welcome to [Myon Venture pvt. Ltd]. We are committed to protecting your privacy and ensuring a secure experience when using our website [www.hamarabrand.com and www.myonventure.com] (the "Site"). This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our Site and use our media buying and planning services
</li><li>2. Information We collect information that you provide directly, as well as information that is automatically collected when you use our Site. This includes: Personal Information: Name, email address, phone number, and payment information provided when you sign up for an account or make a purchase. Usage Data: Information about how you interact with our Site, such as IP address, browser type, pages visited, and the dates/times of your visits. Cookies and Tracking Technologies: We use cookies and similar technologies to collect information about your browsing activities and preferences.
</li><li>3. How We Use Your Information We use the information we collect to: Provide and manage our media buying and planning services. Process transactions and send related communications. Improve our Site and services based on user feedback and analytics. Send marketing communications, including newsletters and promotional offers, if you have opted in to receive them. Ensure the security and integrity of our Site and services.
</li><li>4. Sharing Your Information We can  share your   information like phone no mailid and name in the following circumstances: Service Providers: With third-party service providers and media agency owners or agencies deal in media buying, and selling as well  who assist us in operating our Site with the listing of their media  and delivering our services, such as payment processors and email service providers. Legal Requirements: When required by law or to respond to legal requests, protect our rights, or enforce our terms and conditions. Business Transfers: In connection with a merger, acquisition, or sale of all or a portion of our business.
</li><li>5. we use in-page analytics tools such as mouseflow or clicktale, that helps us understand individual browsing behavior. Again, this data is not personally identifiable since we don't ask users to give us any of their personal data on our site. We also use some chat applets from time-to-time to help our users with any queries they may have, real-time. At times Some users subscribe to our e-mail newsletters. 
</li><li>6. Here we ask them to share their e-mail IDs phone numbers company name and budget for media spending with us. This information is not shared with anybody and we only send new media / new notifications to these users. We do not spam our users with daily or weekly alerts. We only mail them when something worth their while is to be reported. We don't sell or share your personal contact details with anybody, for commercial or other purposes. Your data shared with is confidential with us.
</li><li>7.  The kind of data we get is aggregate and not individual. The data collected gives us an understanding of how many people visited our site, how many of them were unique, the number of pageviews, time spent, location of the users, the pages they viewed, the browser used etc.


</li><li>8.Third-Party Links Our Site may contain links to third-party websites. We are not responsible for the privacy practices or content of these external sites. Please review their privacy policies before providing any personal information.

</li><li>9. Security of Your Information We implement reasonable security measures to protect your information from unauthorized access, disclosure, alteration, or destruction. However, no online transmission or storage system is completely secure, and we cannot guarantee absolute security.
</li><li>10. Your Choices and Rights You have the right to: Access, correct, or delete your personal information. Opt-out of receiving marketing communications by following the unsubscribe instructions in those communications. Disable cookies through your browser settings.
</li><li>11. Changes to This Privacy Policy We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date. We encourage you to review this policy periodically to stay informed about how we are protecting your information.
</li><li>12. Contact Us If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:[Myon Venture pvt. Ltd]
</li>
</ul>
<br/>
<br/>
Email: [hamarabrand@gmail.com]<br/>
Address: [B 101 Amish Park Mira Road Thane ]<br/>
Phone: [+819571115779]<br/>
website [www.hamarabrand.com or www.myonventure.com]






          
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacypolicy;
