import React, { useState, useEffect } from 'react';
import { Link,useNavigate } from 'react-router-dom';

const Findingmedia = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    address: '',
    city: '',
    postalCode: '',
  });
  const [cart, setCart] = useState([]);

  useEffect(() => {

    // Retrieve cart data from localStorage on component mount
    const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
    setCart(storedCart);
  }, []); // Empty dependency array ensures this effect runs only once on mount

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {

    if(localStorage.getItem('usertoken') != "" && localStorage.getItem('usertoken') != null){
          
     
   }else
   {
    navigate("/Login");
   }

    e.preventDefault();
    console.log('Form submitted:', formData);
    // Add logic to handle form submission (e.g., send data to backend, process payment)
  };

  const removeFromCart = (index) => {
    const updatedCart = [...cart];
    updatedCart.splice(index, 1);
    setCart(updatedCart);
    // Update localStorage to reflect the change
    localStorage.setItem('cart', JSON.stringify(updatedCart));


  };

  return (
    <div className="header">
      <div className='container-fluid '>
        <div className='row  innerheader' ></div>
        <div className="container">
          <h2 className="mb-4">Finding Media?</h2>
          <div className="row">
        


          That’s where we come in.<br/>
          At Hamara Brand, our experienced Brand Managers are dedicated to helping you cut through the confusion. We specialize in designing and executing 360° media solutions that cover all aspects of your campaign—from traditional media like Television, Fm Radio, PR, Outdoor, Transit, BTL, Cinema,to digital channels, social media, and everything in between.We work closely with you to understand your brand, your goals, and your audience. With our extensive industry knowledge and district-wise media coverage across India, we ensure that every aspect of your campaign is strategically planned and flawlessly executed.Let us take the stress out of media planning and buying, so you can focus on what you do best—growing your brand. Partner with Hamara Brand, and together.



          
          </div>
        </div>
      </div>
    </div>
  );
};

export default Findingmedia;
