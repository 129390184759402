import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation ,Link} from 'react-router-dom';
import ImagesForItem from './proimage';
import ImagesForslider from './productimageslide';
import './Productdetails.css';

function Productdetails() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const receivedValue = params.get('value');
  const receivedname = params.get('name');

  const [productitems, setProductItems] = useState([]);
  const [cart, setCart] = useState([]);

  useEffect(() => {
    fetchProductItems(receivedValue);
    loadCartFromLocalStorage(); // Load cart items from local storage on component mount
  }, [receivedValue]);

  const fetchProductItems = async (receivedValueid) => {
    try {
      const tokens = '1';
      const response = await axios.get(`https://api.myonventure.com/api/Product/productid?id=${receivedValueid}&tokens=${tokens}`);
      setProductItems(response.data);
    } catch (error) {
      console.error('Error fetching items:', error);
    }
  };

  const addToCart = (item) => {
    const updatedCart = [...cart, item];
    setCart(updatedCart);
    saveCartToLocalStorage(updatedCart); // Save updated cart to local storage
   

  };

  const saveCartToLocalStorage = (cart) => {
    localStorage.setItem('cart', JSON.stringify(cart));
  };

  const loadCartFromLocalStorage = () => {
    const savedCart = localStorage.getItem('cart');
    if (savedCart) {
      setCart(JSON.parse(savedCart));
    }
  };

  return (
    <div className="header">
      <div className='container-fluid'>
        <div className='row innerheader'>
          <div className='col-12 col-md-12'>
            <nav className='pull-left'>
              
              { productitems.map((item, index) => ( 
                
                <ol className="breadcrumb" style={{ float: 'left' }}>             
             
                <li className="breadcrumb-item"><a href="/">Home</a></li>
                <li className="breadcrumb-item breadcrumbtext   toupper" aria-current="page"><a href="/">{item.categoryname}</a></li>
                <li className="breadcrumb-item breadcrumbtext   toupper" aria-current="page"><a href="/">{item.subcategoryname}</a></li>
                <li className="breadcrumb-item breadcrumbtext active pull-right toupper" aria-current="page"><a href="/">{item.name}</a></li>  </ol>   ))
                  }  
                     
            </nav>
          </div>
          <div className='col col-md-5'></div>
          <div className='col col-md-3 text-right'></div>
        </div>

        {productitems.map((item, index) => (
          <div className='row' key={index}>
            <div className='row p-2'>
              <div className='col-12 col-md-7'>
                <div className="borderbox cardstyle1" key={item.id}>
                  <ImagesForslider itemId={item.id} />
                </div>
              </div>
              <div className='col-12 col-md-5'>
                <div className="three">
                  <h4 className="card-title toupper">{item.name}</h4>
                </div>
                <br />
              
                <p className='details'>{item.details}</p>
                <table className='table table-border itemclass'>
                  <tr style={{background:"#fe35a1", color:"#fff"}}>
                    <th>Used For</th>
                    <th>Add Type</th>
                    <th>Lead time (in days)</th>
                    <th>Span</th>
                  </tr>
                  <tr ><td style={{width:'200px'}}>{item.usedfor}</td><td>{item.adtype}</td><td style={{textAlign:'center'}}>{item.leadtimeindays}</td><td style={{width:'70px'}}>{item.span}</td></tr>
                </table>
                {/* <ul className='ulist'>
                  <li><b></b> <br /></li>
                  <li><b></b> <br /></li>
                  <li><b></b> <br /></li>
                  <li><b></b> <br /></li>
                </ul> */}
                <br />
                <div className='minbill'>
                  Minimum Billing: {item.rate}
                </div>
                <br />
                <button type="button" style={{ width: '100%' }} className='btn btn-success btn-lg btn-block' onClick={() => addToCart(item)}>
                  <i className="fa-solid fa-cart-shopping text-left"></i> ADD TO CART
                </button>
                <br />  <br />
                <Link to={{ pathname: '/CheckoutPage' }}  style={{ width: '100%' }} className='btn btn-warning btn-lg btn-block'>
                <i className="fa-solid fa-play"></i> BUY NOW
      </Link>
             
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Productdetails;
