import React, { useState, useEffect } from 'react';
import { Link,useNavigate } from 'react-router-dom';

const Termsofuse = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    address: '',
    city: '',
    postalCode: '',
  });
  const [cart, setCart] = useState([]);

  useEffect(() => {

    // Retrieve cart data from localStorage on component mount
    const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
    setCart(storedCart);
  }, []); // Empty dependency array ensures this effect runs only once on mount

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {

    if(localStorage.getItem('usertoken') != "" && localStorage.getItem('usertoken') != null){
          
     
   }else
   {
    navigate("/Login");
   }

    e.preventDefault();
    console.log('Form submitted:', formData);
    // Add logic to handle form submission (e.g., send data to backend, process payment)
  };

  const removeFromCart = (index) => {
    const updatedCart = [...cart];
    updatedCart.splice(index, 1);
    setCart(updatedCart);
    // Update localStorage to reflect the change
    localStorage.setItem('cart', JSON.stringify(updatedCart));


  };

  return (
    <div className="header">
      <div className='container-fluid '>
        <div className='row  innerheader' ></div>
        <div className="container">
          <h2 className="mb-4">Terms of Use</h2>
          <div className="row">
          Myon Venture pvt. Ltd  Hamara Brand the Media Marketplace 
Terms of Use Last Updated: 
[03/10/2024]
Welcome to Myon Venture pvt. Ltd ! By accessing or using our website located at www.hamarabrand.com , www.myonventure.com (the "Site"), you agree to be bound by these Terms of Use and our Privacy Policy. If you do not agree with these terms, you should not use our website. 
<ul>
    <li>1. EligibilityYou must be at least 18 years old to use our Site. By using the Site, you represent and warrant that you meet this eligibility requirement.
</li>  <li>2. Use of the Site You agree to use the Site for lawful purposes only. You must not use the Site in any way that could damage, disable, overburden, or impair the Site, or interfere with any other party's use of the Site.
</li><li>3. Account RegistrationTo access certain features of the Site, including buying or selling media, you may need to create an account. You agree to provide accurate, current, and complete information during the registration process. You are responsible for maintaining the confidentiality of your account password and for all activities that occur under your account.
</li><li>4. Media Transactions Buying Media: When you purchase media content from the Site, you acquire a license to use the media according to the terms set by the seller or as specified in the media description. You do not acquire ownership of the media itself unless explicitly stated.
</li><li>5.Selling Media: By listing media content for sale, you represent and warrant that you own the rights to the media or have the authority to sell it. You agree to provide accurate and complete information about the media, including any usage rights or restrictions.
</li><li>6. Intellectual Property Rights Media Ownership: All media content available on the Site is owned by or licensed to the sellers who list it. Buyers acquire a license to use the media content as per the terms set forth at the time of purchase.
</li><li>7.Content Usage: You may only use the media content you purchase in accordance with the license terms provided by the seller. Unauthorized reproduction, distribution, or use of the media content is prohibited.
7. Payments and Fees Payment Processing: Payments for media purchases will be processed through the payment methods available on the Site as per laws by Reserve Bank of India guidelines . You agree to pay all fees and charges associated with your purchases, including any applicable taxes.Merchant site shall not save customer card and such related data. A security audit of the merchant may be carried out to check compliance, as and when required. 

It may be understood that the storage of payment card numbers by online merchants, payment aggregators, and ecommerce website will not be allowed going forward. 
</li> <li>8.Fees for Sellers: Sellers may be charged a fee for listing and selling media on the Site. The fee structure will be outlined at the time of account creation or listing.
</li><li>9.Refunds and Disputes Refunds: Refunds for media purchases may be issued only under certain conditions, such as if the media is found to be defective or not as described. All refund requests will be reviewed on a case-by-case basis.
Dispute Resolution: Any disputes between buyers and sellers should be reported to [Myon Vneture pvt. Ltd] for mediation. We will work with both parties to reach a fair resolution, but we do not guarantee the outcome.
</li><li>10. User-Generated Content You may be able to post reviews, comments, and other content related to the media on the Site. You grant [Myon Venture pvt. Ltd] a non-exclusive, royalty-free, perpetual, and worldwide license to use, reproduce, modify, and distribute your content.
</li><li>11.Privacy Your use of the Site is also governed by our Privacy Policy, which is incorporated into these Terms of Use. Please review our Privacy Policy to understand our practices.
</li><li>11. Third-Party LinksThe Site may contain links to third-party websites that are not owned or controlled by [Myon venture pvt.ltd]. We are not responsible for the content, policies, or practices of any third-party websites.
</li><li>12. Limitation of Liability To the maximum extent permitted by law, Myon venture pvt Ltd shall not be liable for any indirect, incidental, special, or consequential damages arising out of or in connection with your use of the Site, including any media transactions.
</li><li>13.IndemnificationYou agree to indemnify and hold Myon Venture pvt Ltd, its affiliates, and their respective officers, directors, employees, and agents harmless from any claims, liabilities, damages, and expenses arising out of your use of the Site or your violation of these Terms of Use.
</li><li>14. Governing LawThese Terms of Use are governed by and construed in accordance with the laws of [Jaipur High Court ], without regard to its conflict of law principles.
</li><li>15. Changes to the Terms We may update these Terms of Use from time to time. Your continued use of the Site after any such changes constitutes your acceptance of the new Terms of Use.
</li><li>16. Contact Us If you have any questions about these Terms of Use, please contact us at Support@hamarabrand.com].
</li></ul>
          
          </div>
        </div>
      </div>
    </div>
  );
};

export default Termsofuse;
