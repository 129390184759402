
import { Route, Routes } from 'react-router-dom';

import './App.css';

import About from './Pages/About';
import Contact from './Pages/Contact';

import Services from './Pages/Services';

import Category from './Pages/Category';
import Home from './Pages/Home.js';
import Header from './componant/header';
import Footer from './componant/footer';
import Subcat from './Pages/Subcat.js';
import CheckoutPage from './Pages/CheckoutPage.js';
import Productdetails from './Pages/Productdetails.js';
import Login from './Pages/Login.js';
import Dashboard from './Pages/Dashboard.js'
import Customerregistration from'./Pages/Customerregistration.js'
import Privacypolicy from './Pages/Privacypolicy.js';
import Termsofuse from './Pages/Termsofuse.js';
import { ScrollRestoration } from 'react-router-dom';
import ForgotPassword from './Pages/ForgotPassword';
import Changepassword from './Pages/Changepassword.js';
import Faq from './Pages/Faq.js';
import Findingmedia from './Pages/Findingmedia.js';
import Verifyemail from './Pages/Verifyemail.js';
import Profileupdate from './Pages/Profileupdate.js';
function App() {
  return (
    
    <div>
    <Header />
   
    <Routes>
      <Route path='/' element={<Home/>}></Route>
      <Route path='/Home' element={<Home/>}></Route>
      <Route path='/About' element={<About/>}></Route>
      <Route path='/Services' element={<Services/>}></Route>
      <Route path='/Contact' element={<Contact />}></Route>
      <Route path='/Category' element={<Category />}></Route>
      <Route path='/Subcat' element={<Subcat />}></Route>
      <Route path='/Productdetails' element={<Productdetails/>}></Route>
      <Route path='/CheckoutPage' element={<CheckoutPage />}></Route>
      <Route path='/Login' element={<Login />}></Route>
      <Route path='/Dashboard' element={<Dashboard />}></Route>
      <Route path='/Customerregistration' element={<Customerregistration />}></Route>
      <Route path='/Termsofuse' element={<Termsofuse />}></Route>
      <Route path='/Privacypolicy' element={<Privacypolicy />}></Route>
      <Route path="/ForgotPassword" element={<ForgotPassword />} />
      <Route path="/Changepassword" element={<Changepassword />} />
      <Route path="/Faq" element={<Faq />} />
      <Route path="/Findingmedia" element={<Findingmedia />} />
      <Route path="/Verifyemail" element={<Verifyemail />} />
      <Route path="/Profileupdate" element={<Profileupdate />} />
    </Routes>
  <Footer/>
    </div>
  );
}

export default App;
