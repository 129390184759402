import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { Link, useLocation } from 'react-router-dom';
function ImagesForslider({ itemId }) {
    
    const [productimageitems, setProductImageItems] = useState([]);
  
    useEffect(() => {


        const fetchImages = async () => {
            try {               const tokens='1';
            console.log(itemId);
              const response = await axios.get('https://api.myonventure.com/api/Product/productimageall?id='+itemId+'&tokens='+tokens+'');
              setProductImageItems(response.data);
              console.log(response.data);
            } catch (error) {
              console.error('Error fetching items:', error);
            }
          };
      // Fetch product images for the given item ID
     
  
      fetchImages();
    }, [itemId]); // Re-fetch images whenever itemId changes
  
    return (
        <div>
        {productimageitems.map((image, index) => (

<div key={index} className={index === 0 ? "carousel-item active" : "carousel-item"}>
<img height={350} src={'https://api.myonventure.com/api/../images/proimage/' + image.image} className="d-block w-100" alt="Slide 1"/>
</div>
         
        ))}
      </div>
    );
  }
  export default ImagesForslider


